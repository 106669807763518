import React from 'react';

function Footer(){
    return (
        <div className='footer'>
            <div className='logo-section'>
                <img src={require('../../images/camera-icon.png')} alt=""/>
            <div className="social-icons">
                <div className="youtube">
                <a href='https://www.youtube.com/@prismkraft' target='_blank'>
                    <img src={require('../../images/youtube_72.png')} alt=""/>
                </a>
                </div>
                <div className="instagram"> 
                <a href='https://www.instagram.com/prismkraft?igsh=MWkwbW83cWhhZnBi' target='_blank'>
                    <img src={require('../../images/instagram_72.png')} alt="instagram"/>
                </a>
                </div>
            </div>
            </div>
        </div>
    )
}

export default Footer;