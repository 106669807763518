import React from 'react';
import '../css/main.css';
import '../App';

function Privacy() {
    return (
      <div className="about-section" style={{"height": "100vh","justify-content": "start"}}>
        <div className="about-me">
          <span className="title">Privacy Policy</span>
            <p>At Prism Kraft, we are committed to protecting your privacy. </p>
            <p>We collect and use personal information, such as your name, email
            address, and phone number, solely for the purpose of providing you
            with our photography services and communicating with you
            effectively. We do not share or sell your information to third
            parties. Your data is securely stored and only accessible to
            authorized personnel. If you have any questions or concerns about
            our privacy practices, please feel free to contact us at
            info@prismkraft.com. We may update this policy from time to time,
            and any changes will be posted on this page.
            </p>
        </div>
      </div>
    );
}

export default Privacy;