import React from 'react';
import { Link } from "react-router-dom";
import HamburgerMenu from './hamburger';

function Header() {
  return (
    <header className='Header'>
      <div className="pk-header-main">
            <div className="logo-section">
                <Link to='/'>
                    <img src={require('../../images/logo_final.png')} alt=""/>
                </Link>
                <div className="tagline">
                <span>By</span>
                <span>Avinash Kalambe</span>
                </div>
            </div>
            <div className="nav-section">
                <div className="about-us">
                    <Link to='/about'>
                        About Us
                    </Link>
                </div>
                <div className="services">
                    <Link to='/prices-services'>
                        Prices & Services
                    </Link>
                </div>
                <div className="portfolio">
                    <Link to='/portfolio'>
                        Portfolio
                    </Link>
                </div>
                <div className="contact-us">
                    <Link to='/contact-us'>
                        Contact Us
                    </Link>
                </div>
                <div className="contact-us">
                    <Link to='/privacy-policy'>
                        Privacy Policy
                    </Link>
                </div>
            </div>
        </div>
            <HamburgerMenu />
    </header>
  );
}

export default Header;