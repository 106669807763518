import React from 'react';

function Home() {
  return (
    <div className="Home">
      <div className="Home-header">
        <div className='shortDescription'>
        <p>Discover the magic of product photography at Prism Kraft, where each photograph is a unique masterpiece, capturing the essence of your brand with a personal touch.</p>
        <p>Let's showcase your brand like never before.</p>
        </div>
        <div className='sample-image1'>
          <div className='first-two'>
          <img src={require('../images/sample_img-1.jpg')} alt="A"/>
          <img src={require('../images/sample_img-2.jpg')} alt="B"/>
          </div>
          <img src={require('../images/sample_img-3.jpg')} alt="C" />
        </div>
      </div>
    </div>
  );
}

export default Home;
