import React from "react";
import ContactUs from  '../components/widgets/form';

function PricesnServices() {
    return(
        <div className="pricesAndServices">
            <div className="main-content">
                <div className="container">
                    <div className="details">
                    <span>Services Offered:</span>
                        <ul>
                            <li>
                            Ecommerce and Online Stores: Capture stunning product images to enhance your online store, attracting customers and boosting sales.
                            </li>
                            <li>
                            Restaurants: Showcase your culinary creations with mouthwatering photos for menus, websites, and promotions.
                            </li>
                            <li>
                            Catalog Shoot for Product Manufacturers: Bring your products to life in catalogs, making them visually appealing to potential buyers.
                            </li>
                            <li>
                            Jewelries: Highlight the intricate details of your jewelry pieces with high-quality, detailed photography.
                            </li>
                            <li>
                            Electronics and Technology: Feature your gadgets and technological innovations with clear and captivating product images.
                            </li>
                            <li>
                            Fashion and Apparel: Display your fashion lines and accessories in a visually appealing manner for catalogs and websites.
                            </li>
                            <li>
                            Beauty and Cosmetics: Showcase your beauty products with professional photography, emphasizing their quality and allure.
                            </li>
                        </ul>
                    </div>

                    <div className="choose_us">
                        <span>Why Choose Us:</span>
                        <ul>
                            <li>
                            Expertise in shooting products across various industries.
                            </li>
                            <li>
                            High-quality, detailed images that captivate and engage.
                            </li>
                            <li>
                            Tailored services to meet the unique needs of your business.
                            </li>
                            <li>
                            Let us bring your products to life through the lens!
                            </li>
                        </ul>
                    </div>
                    <div className="formtext">
                        <span>Kindly Fill the below form for bussiness queries</span>
                    </div>
                </div>
            </div>
            <ContactUs />
        </div>
    )
}

export default PricesnServices;