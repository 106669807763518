import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';

function ContactUs() {
    const form = useRef();
  
    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs
        .sendForm('service_o07am05', 'template_g7s2pga', form.current, {
          publicKey: 'XC8zVAA0ZksVy1B98',
        })
        .then(
          () => {
            console.log('SUCCESS!');
          },
          (error) => {
            console.log('FAILED...', error.text);
          },
        );
    };
  
    return (
        <div id="formContainer">
                <form ref={form} onSubmit={sendEmail} className="contact_form" id="target_form" autoComplete="off">
                <input type="text" id="name" name="name" placeholder="Name" required/>
                <input type="email" id="email" name="email" placeholder="Email" required/>
                <input type="tel" id="contact" name="contact" placeholder="Contact Number" maxLength={10} minLength={10} pattern="[0-9]{10}" required/>
                <textarea id="requirements" name="requirements" style={{"resize": "none"}} placeholder="Describe your requirements" rows="4" cols="50" required></textarea>
                <button type="submit" className="submit_btn">Submit</button>
            </form>
            <div className="toastflag">Thank you for submitting the form, we will contact you very soon</div>
        </div>
    );
  };

  export default ContactUs;