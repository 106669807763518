import React, { useState } from 'react';
import { Link } from "react-router-dom";

function HamburgerMenu() {
//   const [isOpen, setIsOpen] = useState(false);

//   const toggleMenu = () => {
//     setIsOpen(!isOpen);
//   };

  return (
    <div className="mobile-menu">
      <img src={require('../../images/hamburger_menu_icon.png')}></img>
      <div className={'hamburger-menu'}>
        
      <ul className="menu-items">
            <li><Link to='/about'>About Us</Link></li>
            <li><Link to='/prices-services'>Prices & Services</Link></li>
            <li><Link to='/portfolio'>Portfolio</Link></li>
            <li><Link to='/contact-us'>Contact Us</Link></li>
            <li><Link to='/privacy-policy'>Privacy Policy</Link></li>
            </ul>
        </div>
    </div>
  );
}

export default HamburgerMenu;