import React from 'react';
import ImageGrid from './widgets/imagegrid';

const images = [
    { src: require('../images/portfolio_images/Axe White BG Port-min.jpg'), alt: 'Axe White BG' },
    { src: require('../images/portfolio_images/Enfield White BG PORT-min.jpg'), alt: 'Enfield White BG' },
    { src: require('../images/portfolio_images/Facewash with white BG Port-min.jpg'), alt: 'Facewash with white BG' },
    { src: require('../images/portfolio_images/Ganesha with White BG Port-min.jpg'), alt: 'Ganesha with White BG' },
    { src: require('../images/portfolio_images/Glass WHite Port-min.jpg'), alt: 'Glass WHite' },
    { src: require('../images/portfolio_images/Mobile with White BG Port-min.jpg'), alt: 'Mobile with White BG' },
    { src: require('../images/portfolio_images/Wild Stone with White BG Port-min.jpg'), alt: 'Wild Stone with White BG' },
    { src: require('../images/portfolio_images/Wine glass with W BG Port-min.jpg'), alt: 'Wine glass with W BG' },
    { src: require('../images/portfolio_images/IMG_2399 copy-min.jpg'), alt: 'IMG_2399 copy' },
    { src: require('../images/portfolio_images/IMG_2409 copy-min.jpg'), alt: 'IMG_2409 copy' },
    { src: require('../images/portfolio_images/IMG_2419 copy-min.jpg'), alt: 'IMG_2419 copy' },
    { src: require('../images/portfolio_images/IMG_2431 copy-min.jpg'), alt: 'IMG_2431 copy' },
    { src: require('../images/portfolio_images/IMG_2432 copy-min.jpg'), alt: 'IMG_2432 copy' },
    { src: require('../images/portfolio_images/IMG_2434 copy-min.jpg'), alt: 'IMG_2434 copy' },
    { src: require('../images/portfolio_images/IMG_2436 copy-min.jpg'), alt: 'IMG_2436 copy' },
    { src: require('../images/portfolio_images/IMG_2437 copy-min.jpg'), alt: 'IMG_2437 copy' },
    { src: require('../images/portfolio_images/IMG_2443 copy-min.jpg'), alt: 'IMG_2443 copy' },
    { src: require('../images/portfolio_images/IMG_2445 copy-min.jpg'), alt: 'IMG_2445 copy' },
    { src: require('../images/portfolio_images/IMG_2447 copy-min.jpg'), alt: 'IMG_2447 copy' },
    { src: require('../images/portfolio_images/IMG_2448 copy-min.jpg'), alt: 'IMG_2448 copy' },
    { src: require('../images/portfolio_images/IMG_8074-min.JPG'), alt: 'Image 3' },
    { src: require('../images/portfolio_images/IMG_8075-min.JPG'), alt: 'Image 4' },
    { src: require('../images/portfolio_images/IMG_8076-min.JPG'), alt: 'Image 5' },
    { src: require('../images/portfolio_images/Gold chain POrt-min.jpg'), alt: 'Gold chain' },
    { src: require('../images/portfolio_images/Red Necklace Port-min.jpg'), alt: 'Red Necklace' },
    { src: require('../images/portfolio_images/IMG_8090-min.JPG'), alt: 'Image 9' },
    { src: require('../images/portfolio_images/IMG_8091-min.JPG'), alt: 'Image 10' },
    { src: require('../images/portfolio_images/Paratha Portrait- 9217-min.jpg'), alt: 'Paratha Portrait' },
    { src: require('../images/portfolio_images/Pasta Portrait- 9277-min.jpg'), alt: 'Pasta Portrait' },
    { src: require('../images/portfolio_images/Porotta Chicked Portrait- 9240-min.jpg'), alt: 'Porotta Chicken Portrait' },
    { src: require('../images/portfolio_images/IMG_8068-min.JPG'), alt: 'Image 2' },
    { src: require('../images/portfolio_images/Black and White ear ring POrt-min.jpg'), alt: 'Black and White ear ring' },
    { src: require('../images/portfolio_images/Peackok White Port-min.jpg'), alt: 'Peackok White' },
    { src: require('../images/portfolio_images/IMG_8072-min.JPG'), alt: 'Image 1' },
    { src: require('../images/portfolio_images/IMG_8089-min.JPG'), alt: 'Image 8' },
    { src: require('../images/portfolio_images/Brut Perfume White BG Port-min.jpg'), alt: 'Brut Perfume White BG' },
    { src: require('../images/portfolio_images/Wine Glass with Butterfly GOBO PORT-min.jpg'), alt: 'Wine Glass with Butterfly GOBO' },
    { src: require('../images/portfolio_images/Pasta 1 Land Port-min.jpg'), alt: 'Pasta 1 Land' },
    { src: require('../images/portfolio_images/Pizza White BG Land Port-min.jpg'), alt: 'Pizza White BG Land' },
    { src: require('../images/portfolio_images/Paratha & Paneer - 9211-min.jpg'), alt: 'Paratha and paneer' },
    { src: require('../images/portfolio_images/Paratha & Paneer- 9222-min.jpg'), alt: 'Paratha and Paneer' },
    { src: require('../images/portfolio_images/Pasta landscape 9269-min.jpg'), alt: 'Pasta Landscape' },
    { src: require('../images/portfolio_images/Porotta and Chicken 1 closeup-min.jpg'), alt: 'Porotta and Chicken Closeup' },
    { src: require('../images/portfolio_images/Porotta and Chicken Insta-min.jpg'), alt: 'Porotta and Chicken Instagram' },
    { src: require('../images/portfolio_images/IMG_8078-min.JPG'), alt: 'Image 6' },
    { src: require('../images/portfolio_images/IMG_8088-min.JPG'), alt: 'Image 7' },
];

function Portfolio() {
    return(
        <div className='portfolio-section'>
            <h1 style={{'textAlign': 'center'}}>Image Gallery</h1>
            <ImageGrid images={images} />
        </div>
    )
}

export default Portfolio;