import React from 'react';
import '../css/main.css';
import '../App';

function About() {
    return (
        <div className='about-section'>
            <div className='about-me'>
                <span className='title'>About Prism Kraft</span>
                <p>Hi, I'm Avinash Kalambe, the driving force behind Prism Kraft Photography. A few years ago, a spark ignited within me—the realization that photography could be more than just a hobby; it could be a profound expression of passion and a fulfilling profession. Life led me down a different path for a while, navigating the corporate world, but the fire within remained unextinguished.</p>
                <p>Amidst the hustle, the dream of creating Prism Kraft Photography was born. It's a testament to reclaiming what truly drives me, a return to that initial spark. This venture represents the culmination of a journey—a journey that started with a simple thought and persevered through the twists and turns of life.</p>
                <p>Why Prism Kraft? Because it's not just a name; it's a manifestation of a dream deferred and now realized. It's the embodiment of the route map I had in my mind years ago, now brought to life.</p>
                <p>Beyond the lens, I find solace in the fact that passion, once discovered, can be reignited. Prism Kraft is more than a photography venture; it's a celebration of the resilience of dreams.</p>
                <p>I invite you to join me on this journey, where every photograph tells a story, and every moment captured is a reflection of the steadfast pursuit of what truly drives me.</p>
            </div>
            <div className="lead-img">
                <img src={require("../images/avinash.jpg")} alt="lead-img"/>
                <span style={{"marginTop":'10px'}}>Avinash Kalambe</span>
                <span>Photographer</span>
            </div>
        </div>
    );
}

export default About;