import React from "react";
import '../App';

function Contact() {
    return(
        <div className="contact-section">
            <div className="content">
                <div className="left_section">
                    <div className="header">
                        <span>Get in Touch:</span>
                        <p>We're here to answer any questions you may have. Reach out to us and let's start a conversation about how Prism Kraft Photography can elevate your visual presence.</p>
                    </div>
                    <div className="body">
                        <ul>
                            <li>
                                <p>Contact Information:</p>
                                <span>Phone: <b>+91- 8108926569</b></span><br className="line-break"></br>
                                <span>Email: <b>info@prismkraft.com</b></span>
                            </li>
                            <li>
                                <p>Business Hours:</p>
                                <span>We're available to assist you during the following hours:</span><br className="line-break"></br>
                                <span>Monday to Saturday: <b>10 AM - 10 PM</b></span><br className="line-break"></br>
                                <span>Sunday: <b>Closed</b></span>
                            </li>
                            <li>
                                <p>Connect on Social Media:</p>
                                <span>Stay updated and connect with us on social media platforms for the latest updates, behind-the-scenes glimpses, and more:</span><br className="line-break"></br>
                                <span>Instagram: <b>PRISMKRAFT</b></span>
                            </li>
                        </ul>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Contact;