import React from 'react';

const ImageGrid = ({ images }) => {
  return (
    <div className="image-grid">
      {images.map((image, index) => (
        <img key={index} className='portfolioimages img-thumbnail' src={image.src} alt={image.alt} />
      ))}
    </div>
  );
};

export default ImageGrid;