import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/home.js';
import About from './components/about.js';
import Header from './components/widgets/header.js';
import Footer from './components/widgets/footer.js';
import Portfolio from './components/portfolio.js';
import Contact from './components/contact-us.js';
import PricesnServices from './components/pricesnServices.js';
import { HashRouter } from 'react-router-dom';
import HamburgerMenu  from './components/widgets/hamburger';
import Privacy from './components/privacy.js';

function App() {
  return (
    <HashRouter>
      <div>
      <Header />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/about" element={<About />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/prices-services" element={<PricesnServices />} />
        <Route path="/privacy-policy" element={<Privacy />} />
      </Routes>
      <Footer />
      </div>
    </HashRouter>
  );
}

export default App;
